


















































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import OrderDispatchDialogue from "@/vue/components/dialogues/OrderDispatchDialogue.vue";
import OrderReadyForDispatchDialogue from "@/vue/components/dialogues/OrderReadyForDispatchDialogue.vue";
import store from "@/store/store";
import utils from "@/utilities/Utils";
import { Country, ICountry } from "@/model/Country";
import { OrderSearchParameters } from "@/model/Api/SearchParams/OrderSearchParameters";
import { OrderSearchResult, IOrderSearchResult } from "@/model/Api/SearchResults/OrderSearchResult";
import { StateChanger } from "vue-infinite-loading";
import { Supplier } from "@/model/Supplier";
import { UserRole } from "@/model/Enums";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        OrderReadyForDispatchDialogue,
        OrderDispatchDialogue
    }
})

export default class Orders extends Vue {

    async mounted(): Promise<void> { 
        this.loadSuppliers();
        this.loadDeliveryCountries();
    }

    //
    // -- properties
    //

    private ordersHeaders = [
        { text: "Order No.", value: "id", sortable: true },
        { text: "PO No.", value: "poNumber", sortable: true },
        { text: "Supplier", value: "supplier", sortable: false },
        { text: "Created", value: "createdDate", sortable: true },
        { text: "Country", value: "country", sortable: true },
        { text: "Confirmed (UK)", value: "confirmedDate", sortable: true },
        { text: "Confirmed (Where Required)", value: "confirmedLocalDate", sortable: true },
        { text: "RFD Date", value: "rfdDate", sortable: true },
        { text: "Dispatched", value: "dispatchedDate", sortable: true }
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new OrderSearchParameters();
    private suppliers: Array<Supplier> = [];
    private countries: Array<Country> = [];
    private orderList: Array<OrderSearchResult> = [];

    private get isAdmin(): boolean {
        return store.state.signedInUser?.role == UserRole.SuperAdmin || store.state.signedInUser?.role == UserRole.Admin;
    }

    get rdfSelectedCount(): number {
        var retVal = 0;
        this.orderList.forEach(o => { if (o.rdfSelected) retVal++; });
        return retVal;
    }
    
    get dispatchSelectedCount(): number {
        var retVal = 0;
        this.orderList.forEach(o => { if (o.dispatchSelected) retVal++; });
        return retVal;
    }

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.supplierID")
    private onSupplierIDChanged() {
        if (this.searchParameters.supplierID == undefined) {
            this.searchParameters.supplierID = 0;
        }
        this.debouncedRefreshSearch();
    }

    @Watch("searchParameters.countryID")
    private onCountryIDChanged() {
        if (this.searchParameters.countryID == undefined) {
            this.searchParameters.countryID = "";
        }
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    open(order: IOrderSearchResult): void {
        this.$router.push("/order/" + order.id);
    }

    setReadyToDispatchDate(): void {
        var orderIDs: Array<number> = [];
        this.orderList.forEach(o => {
            if (o.rdfSelected) orderIDs.push(o.id)
        });

        const dialog: OrderReadyForDispatchDialogue = this.$refs.orderRfdDialogue as OrderReadyForDispatchDialogue;
        dialog.open(orderIDs);
    }

    setDispatchDate(): void {
        var orderIDs: Array<number> = [];
        this.orderList.forEach(o => {
            if (o.dispatchSelected) orderIDs.push(o.id)
        });

        const dialog: OrderDispatchDialogue = this.$refs.orderDispatchDialogue as OrderDispatchDialogue;
        dialog.open(orderIDs);
    }

    refreshSearch(): void {
        this.orderList = [];
        this.searchParameters.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    reset(): void {
        this.searchParameters.searchParameters.searchText = "";
        this.searchParameters.supplierID = 0;
        this.searchParameters.countryID = "";
        this.debouncedRefreshSearch();
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/order/search", this.searchParameters);
        if (response.list.length) {
            this.orderList.push(...response.list.map((o: IOrderSearchResult) => new OrderSearchResult(o)));
            this.searchParameters.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }

    private async loadSuppliers() {
        this.searchParameters.supplierID = 0;
        this.suppliers = [];
        const response = await apiClient.get("/api/supplier/userSuppliers");
        this.suppliers.push(...response);
    }

    private async loadDeliveryCountries() {
        this.countries = [];
        const response = await apiClient.get("/api/country/deliveryCountries");
        this.countries.push(...response.map((c: ICountry) => new Country(c)));
    }
}
