import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderSearchResult {
    id: number;
    poNumber: string;
    supplier: string;
    createdDate: Date;
    country: string;
    confirmedDate: Date;
    confirmedLocalDate: Date;
    rfdDate: Date;
    dispatchedDate: Date;
}

export class OrderSearchResult implements IOrderSearchResult {

    constructor(data?: IOrderSearchResult) {
        if (data) this.update(data);
    }

    update(data: IOrderSearchResult): void {
        mapData(data, { 
            root: () => this
        });
    }

    id: number = 0;
    poNumber: string = "";
    supplier: string = "";
    createdDate: Date = new Date(utils.emptyDateValue);
    country: string = "";
    confirmedDate: Date = new Date(utils.emptyDateValue);
    confirmedLocalDate: Date = new Date(utils.emptyDateValue);
    rfdDate: Date = new Date(utils.emptyDateValue);
    dispatchedDate: Date = new Date(utils.emptyDateValue);

    rdfSelected: boolean = false;
    dispatchSelected: boolean = false;

    get isConfirmed(): boolean {
        return utils.hasDateValue(this.confirmedDate);
    }

    get hasRFDDate(): boolean {
        return utils.hasDateValue(this.rfdDate);
    }

    get isDispatched(): boolean {
        return utils.hasDateValue(this.dispatchedDate);
    }
}